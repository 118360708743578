require('./bootstrap');

import {MyUploadAdapter} from "./custom_plugins";

const DecoupledEditor = require('@ckeditor/ckeditor5-build-decoupled-document');
require('@ckeditor/ckeditor5-build-decoupled-document/build/translations/es');

let editor;

window.initPostEdition = function (data) {

    initCkEditor(data);

    addImagePreview();
}

function initCkEditor(data) {
    DecoupledEditor
        .create(document.querySelector('#body'), {
            language: 'es',
            mediaEmbed: {previewsInData: true},
            extraPlugins: [MyCustomUploadAdapterPlugin]
        })
        .then(newEditor => {
            const toolbarContainer = document.querySelector('#toolbar-container');
            toolbarContainer.appendChild(newEditor.ui.view.toolbar.element);

            if (data)
                newEditor.setData(data);

            editor = newEditor;
            addImageSubmitListener();
        })
        .catch(error => {
            console.error(error);
        });
}

function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
    };
}

function addImageSubmitListener() {
    document.querySelector('#submit').addEventListener('click', () => {

        const $div = document.createElement('div');
        $div.innerHTML = editor.getData()

        const $images = $div.getElementsByTagName('img');
        if ($images) {
            for (let $img of $images) {
                $img.classList.add('card-img-top')
            }
        }

        document.querySelector('#editor_content').innerText = $div.innerHTML;
    });
}

function addImagePreview() {

    function readURL(input) {
        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = function (e) {

                const $imgContainer = document.querySelector('#image-preview-container');
                $imgContainer.classList.remove('d-none');

                const $img = document.querySelector('#image-preview');
                $img.setAttribute('src', e.target.result);
            }

            reader.readAsDataURL(input.files[0]); // convert to base64 string
        }
    }

    const $image = document.querySelector('#image');
    if ($image) {
        $image.addEventListener('change', function () {
            readURL(this);
        })
    }
}
